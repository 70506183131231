import { lazy, Suspense } from "react";
import { observer } from "mobx-react-lite";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { RootStore, StoreProvider } from "./store/Store";

import { SnackbarProvider } from "notistack";

import Announcement from "./components/Announcement";
import AppBarAvatar from "./components/AppBarAvatar";
import Login from "./pages/Login";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Test from "./pages/Test";

import CircularProgress from "@mui/material/CircularProgress";

import { lightTheme, darkTheme } from "./common/consts";

// import UserTableTemplate from "./components/UserTableTemplate";

const Home = lazy(() => import("./pages/Home"));
const Statistics = lazy(() => import("./pages/Statistics"));
const Users = lazy(() => import("./pages/Users"));
const Clients = lazy(() => import("./pages/Clients"));
const Coordinators = lazy(() => import("./pages/Coordinators"));
const Workers = lazy(() => import("./pages/Workers"));
const LogBooks = lazy(() => import("./pages/LogBooks"));
const Earnings = lazy(() => import("./pages/Earnings"));
const MonthlyView = lazy(() => import("./pages/MonthlyView"));
const EarningView = lazy(() => import("./pages/EarningView"));
const UserTableTemplate = lazy(() => import("./components/UserTableTemplate"));

const store = new RootStore();

const App = observer(() => {
  let theme = store.isDarkTheme
    ? createTheme(darkTheme)
    : createTheme(lightTheme);

  // this become mobix State
  // const announceText = "";
  // const announceText = "Zakup aplikacije istice za 22 dana. Molimo Vas da u doglednom vremenu izvrsite uplatu.";

  return (
    <>
      <StoreProvider store={store}>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Suspense fallback={<CircularProgress />}>
              <Router>
                <Announcement text={store.user.announceText} />
                <AppBarAvatar />

                {store.isLogged ? (
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route exact path="/" element={<Home />} />

                    <Route path="/users" element={<Users />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/coordinators" element={<Coordinators />} />
                    <Route path="/workers" element={<Workers />} />

                    <Route path="/workinghours" element={<LogBooks />} />
                    <Route path="/earnings" element={<Earnings />} />

                    <Route path="/monthlyview" element={<MonthlyView />} />
                    <Route path="/earningview" element={<EarningView />} />
                    <Route path="/statistics" element={<Statistics />} />
                    {/* <Route path="/completeview" element={<Contact />} /> */}
                    <Route path="/test" element={<Test />} />

                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/contact/test" element={<Test />} />
                    <Route path="/usertable" element={<UserTableTemplate />} />
                  </Routes>
                ) : (
                  <Login />
                  // <Test />
                )}
              </Router>
            </Suspense>
          </ThemeProvider>
        </SnackbarProvider>
      </StoreProvider>
    </>
  );
});

export default App;
