import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { Divider, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStore } from "../store/Store";
import { Person } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

import { useTranslation } from "react-i18next";

const DrawerNavigate = observer(({ ...props }) => {
  const store = useStore();
  const location = useLocation();
  const { t } = useTranslation();

  const { open, setOpen } = props;
  const [openRegistries, setOpenRegistries] = useState(true);
  const [openRecords, setOpenRecords] = useState(true);
  const [openView, setOpenView] = useState(true);

  const setStateByName = (name) => {
    switch (name) {
      case "registries":
        return setOpenRegistries(!openRegistries);
      case "records":
        return setOpenRecords(!openRecords);
      case "view":
        return setOpenView(!openView);
      default:
        return console.log("Incorect input Switch: " + name);
    }
  };
  const getStateByName = (name) => {
    switch (name) {
      case "registries":
        return openRegistries;
      case "records":
        return openRecords;
      case "view":
        return openView;
      default:
        return console.log("Incorect return Switch: " + name);
    }
  };
  const closeSlow = () => {
    const timeout = setTimeout(() => {
      setOpen(false);
    }, 1000);

    return () => clearTimeout(timeout);
  };

  const sxStyle = {
    height: "30px",
    width: "200px",
    mx: 2,
    color: store.isDarkTheme ? "#cccccc" : "#111111",
    "&:hover": {
      color: store.isDarkTheme ? "yellow" : "blue",
      cursor: "pointer",
    },
  };

  function MenuPerson() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        onClick={() => setOpen(false)}
      >
        <Person sx={{ fontSize: 90, mx: "auto" }} />
      </Grid>
    );
  }

  function MenuUser() {
    return (
      <Typography
        level="body3"
        fontWeight="bold"
        sx={{
          color: store.isDarkTheme ? "yellow" : "blue",
          mx: "auto",
          mb: 1,
        }}
      >
        {store.isLogged ? store.user.username : "User not logged in"}
      </Typography>
    );
  }

  const menuList = {
    itemGroup: [
      {
        title: "registries",
        itemList: [
          { desc: "users", link: "/users", banned: ["admin", "user"] },
          { desc: "clients", link: "/clients", banned: ["user"] },
          { desc: "coordinators", link: "/coordinators", banned: ["user"] },
          { desc: "workers", link: "/workers", banned: ["user"] },
        ],
      },
      {
        title: "records",
        itemList: [
          { desc: "working_hours", link: "/workinghours", banned: ["user"] },
          { desc: "earnings", link: "/earnings", banned: ["user"] },
        ],
      },
      {
        title: "view",
        itemList: [
          { desc: "monthly_view", link: "/monthlyview", banned: [""] },
          { desc: "earnings_view", link: "/earningView", banned: [""] },
          { desc: "statistics", link: "/statistics", banned: ["user"] },
          // { desc: "complete_view", link: "/completeview", banned: [""] },
          { desc: "Test", link: "/test", banned: [""] },
        ],
      },
    ],
  };

  return !store.isLogged ? null : (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        transition: "all 1s",
        transitionTimingFunction: "ease-in-out",
      }}
    >
      <Grid item>
        <Drawer
          // {...props}
          open={open}
          onClose={() => setOpen(false)}
          anchor={"left"}
        >
          <MenuPerson />
          <MenuUser />

          <Divider />

          {menuList.itemGroup.map((topLevel, index) => (
            <Fragment key={1000 + index}>
              <Typography
                id="basic-list-demo"
                level="body3"
                textTransform="uppercase"
                fontWeight="bold"
                sx={{ ...sxStyle, marginLeft: 0 }}
                onClick={() => setStateByName(topLevel.title)}
              >
                <IconButton aria-label="expand" size="small">
                  {getStateByName(topLevel.title) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                {t(topLevel.title)}
              </Typography>

              <Collapse in={getStateByName(topLevel.title)} timeout="auto">
                {topLevel.itemList.map((secondLevel, index) => (
                  <Fragment key={1100 + index}>
                    <List dense disablePadding={true}>
                      <ListItem
                        button
                        selected={secondLevel.link === location.pathname}
                        // disabled={secondLevel.banned.includes("admin")}
                        disabled={secondLevel.banned.some((element) => {
                          return (
                            element.toLowerCase() ===
                            store.user.role.toLowerCase()
                          );
                        })}
                        onClick={() => closeSlow()}
                        // onClick={() => setOpen(false)}
                        sx={{ ...sxStyle, marginLeft: 2 }}
                      >
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to={secondLevel.link}
                        >
                          <ListItemText primary={t(secondLevel.desc)} />
                        </Link>
                      </ListItem>
                    </List>
                  </Fragment>
                ))}
              </Collapse>

              <Divider />
            </Fragment>
          ))}
        </Drawer>
      </Grid>
    </Grid>
  );
});

export default DrawerNavigate;
