import { Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const releaseDate = new Date("1965-07-24");
  const timeDifference = new Date() - releaseDate;
  const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "400px",
        // bgcolor: "yellow",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>{t("welcome_message")}</h1>
      <h3>{t("days_since_release", { number_of_days })}</h3>
      <p>Ovaj dio texta nije preveden (mijenjano na HP-u)</p>
    </Container>
  );
};

export default observer(About);
