import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import { useFormControls } from "./LoginFormControls";
import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";

export const LoginForm = () => {
  const { t } = useTranslation();
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    resetPassword,
    errors,
    loading,
  } = useFormControls();

  const inputFieldValues = [
    {
      name: "email",
      label: t("email"),
      id: "my-email",
    },
    {
      name: "password",
      label: t("password"),
      type: "password",
      id: "my-password",
    },
  ];

  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {t("copyright")}
        {" © "}
        {/* <Link color="inherit" href="https://fancy-bl.com/">
        Piramida
      </Link> */}
        Piramida {new Date().getFullYear()}.
      </Typography>
    );
  }

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main", mx: "auto" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        sx={{ my: 1, fontWeight: "bold" }}
      >
        {t("login")}
      </Typography>
      {inputFieldValues.map((inputFieldValue, index) => {
        return (
          <TextField
            sx={{ my: 1 }}
            key={index}
            onChange={handleInputValue}
            onBlur={handleInputValue}
            name={inputFieldValue.name}
            label={inputFieldValue.label}
            type={inputFieldValue.type}
            error={errors[inputFieldValue.name]}
            multiline={inputFieldValue.multiline ?? false}
            fullWidth
            rows={inputFieldValue.rows ?? 1}
            autoComplete="none"
            {...(errors[inputFieldValue.name] && {
              error: true,
              helperText: errors[inputFieldValue.name],
            })}
          />
        );
      })}
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={resetPassword} disabled={loading}>
          {t("reset_password")}
        </Button>
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!formIsValid() || loading}
      >
        {t("login")}
      </Button>
      <Copyright sx={{ mt: 5 }} />
    </form>
  );
};
