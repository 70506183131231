import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerNavigate from "./DrawerNavigate";
import { useStore } from "../store/Store";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { PersonOutline } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { Grid, IconButton, MenuItem, Select } from "@mui/material";

import ReactCountryFlag from "react-country-flag";
// https://www.translatorscafe.com/cafe/ISO-3166-Country-Codes.htm

const languages = [
  { code: "gb", name: "English" },
  { code: "de", name: "Germany" },
  { code: "sk", name: "Slovakia" },
  { code: "fr", name: "Français" },
  // { code: "ar", name: "العربية", dir: "rtl" },
];

const AppBarAvatar = observer(() => {
  const [open, setOpen] = useState(false);
  const store = useStore();

  //console.log(store.user);

  const currentLanguageCode = cookies.get("i18next") || "US";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [code, setCode] = useState(currentLanguageCode);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setCode(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const Lang = () => {
    return (
      <Select
        value={code}
        size="small"
        onChange={handleChange}
        sx={{
          height: "2.2em",
          boxShadow: 6,
          borderRadius: 2,
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        renderValue={(value) => {
          return (
            <ReactCountryFlag
              svg
              countryCode={code}
              style={{
                // marginLeft: "5px",
                // marginRight: "5px",
                width: "25px",
                height: "25px",
              }}
            />
          );
        }}
      >
        <Grid container justifyContent="center" sx={{ borderBottom: 1, borderColor: 'gray' }}>
          <em><b>Language</b></em>
        </Grid>
        {languages.map(({ code, name }) => (
          <MenuItem key={code} value={code}>
            <ReactCountryFlag
              svg
              countryCode={code}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                width: "25px",
                height: "25px",
              }}
            />
            {name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            //sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1, mx: 2 }}>
            Piramida
          </Typography>

          <Button
            onClick={store.toggleTheme}
            sx={{
              m: 0,
              my: "auto",
              p: 0,
              minWidth: "32px",
            }}
          >
            <img
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "8px",
              }}
              src={store.piramideSmall}
              alt=""
            />
          </Button>

          <Button>
            <Lang />
          </Button>

          {store.isLogged ? (
            <>
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to="/login"
                onClick={() => store.updateIsLogged(false)}
              >
                <Button
                  // variant="outlined"
                  color="inherit"
                  endIcon={<LogoutIcon />}
                  sx={{ boxShadow: 6 }}
                >
                  Logout
                </Button>
              </Link>
            </>
          ) : (
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to="/login"
              onClick={() => console.log("login")}
            >
              <Button
                // variant="outlined"
                color="inherit"
                endIcon={<PersonOutline sx={{ mx: 1 }} />}
                sx={{ boxShadow: 6 }}
              >
                Login
              </Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
      <DrawerNavigate open={open} setOpen={setOpen} />
    </Box>
  );
});

export default AppBarAvatar;
