import { observer } from "mobx-react-lite";
import { useStore } from "../store/Store";

// import UserTableTemplate from "../components/UserTableTemplate";
import DialogTemplate from "../components/DialogTemplate";
import { useState } from "react";
import { Button } from "@mui/material";
import BarChartTemplate from "../components/statistics/BarChartTemplate";

const Test = observer(() => {
  const store = useStore();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = (reason) => {
    switch (reason) {
      case 'OK':
        alert("returned: " + reason); break;
      case 'CANCEL':
        alert("returned: " + reason); break;
      default: return;
    }
    setIsOpen(false);
  };


  return (
    <>
      <div>Test Page</div>
      <h3>user: {store.user.username}</h3>
      <h3>theme: {store.isDarkTheme ? "dark" : "light"}</h3>

      {/* <UserTableTemplate /> */}

      <Button color="primary" onClick={handleOpen}>
        I Agree
      </Button>

      <DialogTemplate isOpen={isOpen} handleClose={handleClose} />

      <div style={{ width: 900, height: 300 }}>
        <BarChartTemplate data={{ chartData: data, dataKey: 'name', chartNames: dataNames }} />
      </div>
    </>
  );
});

export default Test;


// const data = [
//   { name: 'Page A', uv: 4000, pv: 2400, },
//   { name: 'Page B', uv: 3000, pv: 1398, },
//   { name: 'Page C', uv: 2000, pv: 9800, },
//   { name: '1', uv: 300, pv: 446 },
//   { name: '2', uv: 145, pv: 230 },
//   { name: '3', uv: 100, pv: 345 },

// ];
// const dataNames = [
//   { dataKey: "uv", fill: "#FF0000" },
//   { dataKey: "pv", fill: "#FFA500" },
// ];


const data = [
  { name: 'Period A', uv: 300, pv: 446 },
  { name: 'Period B', uv: 145, pv: 230 },
  { name: 'Period C', uv: 100, pv: 345 },
  { name: 'Period D', uv: 8, pv: 440 },
  { name: 'Period E', uv: 100, pv: 321 },
  { name: 'Period F', uv: 9, pv: 235 },
];
const dataNames = [
  { dataKey: "uv", fill: "#FF0000" },
  { dataKey: "pv", fill: "#FFA500" },
];